import { z } from "zod";

import {
    advancedDataTableSchema,
    dataPartSchema,
    textPartSchema,
} from "@hsl/fund-page/schemas";

import FundPageContent from "../../components/FundPageContent";
import usePageSection from "../../hooks/usePageSection";
import { DealingAndLiquidity, PlatformAvailability } from "./components";

const fundFactsSchema = dataPartSchema.extend({
    canvas: dataPartSchema.extend({
        part: z.object({
            keyFacts: advancedDataTableSchema,
            keyFactsNote: textPartSchema.optional(),
            fundObjective: textPartSchema.optional(),
            dealingAndLiquidity: advancedDataTableSchema,
            legalAndRegulatory: advancedDataTableSchema,
        }),
    }),
});

interface Props {
    dataKey: string;
}

const FundFacts = ({ dataKey }: Props) => {
    const { canvas, title } = usePageSection(dataKey, fundFactsSchema);
    const {
        keyFacts,
        keyFactsNote,
        fundObjective,
        dealingAndLiquidity,
        legalAndRegulatory,
    } = canvas.part;

    return (
        <FundPageContent.Section
            dataKey={dataKey}
            title={title}
            className="flex-col"
        >
            <div className="flex flex-col gap-x-6 gap-y-4 xl:flex-row xl:items-stretch">
                {/* ---- KEY FACTS ---- */}
                <FundPageContent.Box
                    containerClassName="flex-1"
                    className="h-full"
                >
                    <FundPageContent.Header>
                        {keyFacts.title}
                    </FundPageContent.Header>
                    <FundPageContent.KeyValueList
                        data={keyFacts.part.data}
                        labelClassName="whitespace-nowrap"
                    />
                    {fundObjective && (
                        <div className="pt-4">
                            <FundPageContent.Header h5>
                                {fundObjective.title}
                            </FundPageContent.Header>
                            <FundPageContent.P>
                                {fundObjective.part.data}
                            </FundPageContent.P>
                        </div>
                    )}
                    {keyFactsNote && (
                        <FundPageContent.Footnote withBorder>
                            {keyFactsNote.part.data}
                        </FundPageContent.Footnote>
                    )}
                </FundPageContent.Box>

                {/* ---- DEALING AND LIQUIDITY ---- */}
                <FundPageContent.Box
                    containerClassName="flex-1"
                    className="h-full"
                >
                    <DealingAndLiquidity data={dealingAndLiquidity} />
                </FundPageContent.Box>

                {/* ---- LEGAL AND REGULATORY ---- */}
                <FundPageContent.Box
                    containerClassName="flex-1"
                    className="h-full"
                >
                    <FundPageContent.Header>
                        {legalAndRegulatory.title}
                    </FundPageContent.Header>
                    <ul className="text-sm sm:text-base">
                        {legalAndRegulatory.part.data.map((keyVal) => (
                            <li key={keyVal[0]} className="my-2">
                                <div>{keyVal[0]}</div>
                                <div className="text-purple font-semibold">
                                    {keyVal[1]}
                                </div>
                            </li>
                        ))}
                    </ul>
                </FundPageContent.Box>
            </div>
            <PlatformAvailability />
        </FundPageContent.Section>
    );
};

const FundFactsWithErrorBoundary = (props: Props) => {
    return (
        <FundPageContent.Wrapper dataKey={props.dataKey}>
            <FundFacts {...props} />
        </FundPageContent.Wrapper>
    );
};

export default FundFactsWithErrorBoundary;
