import { type ReactNode } from "react";
import dayjs from "dayjs";
import { decode as htmlDecode } from "html-entities";

import cx from "@hsl/core/utils/cx";
import { PostListItem } from "@hsl/fund-page/schemas";
import { createTemplate } from "@hsl/fund-page/services";

import Card from "../Card";
import FundPageContent from "../FundPageContent";
import {
    GreyCurve,
    NewPage as NewPageIcon,
    NewsAwards,
    NewsEsg,
    NewsInvestmentViews,
    PodcastInterview,
    Webinar as WebinarIcon,
} from "../icons";
import Skeleton from "../Skeletons";
import TextDataPart from "../TextDataPart";

export const NewsAndViewsCard = ({
    type,
    image,
    category_name,
    date,
    title,
    synopsis,
    authors_to_show,
    read_url,
    funds,
    publicationImage,
}: PostListItem) => {
    const tenant = funds[0]?.tenant;

    let safeSynopsis = synopsis;
    if (type === "text_external") {
        const decodedSynopsis = htmlDecode(synopsis);
        const template = createTemplate();
        template.innerHTML = `<div>${decodedSynopsis}</div>`;
        safeSynopsis =
            (template.content.children[0] as HTMLElement)?.textContent ??
            decodedSynopsis;
    }

    let CardIcon: ReactNode;
    switch (category_name) {
        case "Interview or Podcast":
            CardIcon = <PodcastInterview width={40} height={40} />;
            break;
        case "Awards":
            CardIcon = <NewsAwards width={40} height={40} />;
            break;
        case "ESG":
            CardIcon = <NewsEsg width={40} height={40} />;
            break;
        case "In the Press":
            CardIcon = <WebinarIcon width={40} height={40} />;
            break;
        default:
            CardIcon = <NewsInvestmentViews width={40} height={40} />;
    }

    return (
        <Card.Container href={read_url} newTab={type === "text_external"}>
            <Card.Image
                imgURL={
                    image !== "None"
                        ? image
                        : "/static/images/post-card-default.jpg"
                }
            >
                <div
                    className={cx(
                        "bg-primary",
                        {
                            "bg-evenlode": tenant === "Evenlode",
                            "bg-zennor": tenant === "Zennor",
                            "bg-chelverton": tenant === "Chelverton",
                            "bg-spring": tenant === "Spring",
                        },
                        "absolute -bottom-5 left-4 rounded-full p-1.5",
                    )}
                >
                    {CardIcon}
                </div>
            </Card.Image>
            <Card.Body className="pt-8">
                <Card.Brow>
                    <span className="uppercase">{category_name}</span>
                    {" | "}
                    <span>{dayjs(date).format("D MMMM YYYY")}</span>
                </Card.Brow>
                <Card.Title className="pt-1">{htmlDecode(title)}</Card.Title>
                <TextDataPart className="flex-1" data={safeSynopsis} decode />
                <Card.Footer className="h-16">
                    {publicationImage ? (
                        <div className="flex justify-between pt-2">
                            <img
                                src={publicationImage.src}
                                alt={publicationImage.alt}
                                className="max-h-[45px] max-w-[150px]"
                            />
                            <NewPageIcon />
                        </div>
                    ) : authors_to_show.length > 0 ? (
                        <>
                            <div className="font-semibold">
                                {authors_to_show.join(" & ")}
                            </div>
                            <div>{funds[0]?.name}</div>
                        </>
                    ) : null}
                </Card.Footer>
            </Card.Body>
        </Card.Container>
    );
};

export const NewsAndViewsCardSkeleton = ({
    className,
}: {
    className?: string;
}) => <Skeleton className={className} width={"378px"} height={"533px"} />;

export const NewsAndViewsCardListSkeleton = ({
    withCurve = true,
    className,
    numCards = 3,
}: {
    withCurve?: boolean;
    className?: string;
    numCards?: number;
}) => (
    <>
        <div className={className}>
            {withCurve && <GreyCurve className="mx-auto -mb-1" />}
            <div className="bg-offWhite mx-auto pb-6">
                <div className="container mx-auto">
                    <FundPageContent.HeaderSkeleton height={"2.5rem"} />
                    <div className="flex flex-wrap gap-6 pt-8">
                        {Array.from(Array(numCards)).map((_, i) => (
                            <NewsAndViewsCardSkeleton
                                key={`homeNewsAndViewsSkel-${i}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>
);
