import { z } from "zod";

import cx from "@hsl/core/utils/cx";
import usePartData from "@hsl/fund-page/hooks/usePartData";
import {
    advancedDataTableData,
    AdvancedDataTableData,
    advancedDataTableSchema,
} from "@hsl/fund-page/schemas";
import FundPageContent from "@hsl/spring-fund-page/src/components/FundPageContent";

import { Skeleton, TableSkeleton } from "../../../components/Skeletons";
import Table from "../../../components/Table";
import useFundPageStore from "../../../store/store";
import { usePerformanceContext } from "../context";

export const CalendarYearPerformance = (
    props: z.infer<typeof advancedDataTableSchema>,
) => {
    const { audienceId, base_url, routeId, languageId } = useFundPageStore(
        (store) => store.config,
    );
    const { activeShareClass } = usePerformanceContext();
    const { part } = props;
    const { title } = part;

    const tableData = usePartData<AdvancedDataTableData>(
        Number(props.part.partId),
        {
            shareClassID: String(activeShareClass.id),
            audienceId: String(audienceId),
            baseUrl: base_url,
            routeId: String(routeId),
            languageId: String(languageId),
        },
        advancedDataTableData,
    );

    if (tableData.isLoading) {
        return (
            <div>
                <TableSkeleton numRows={1} />
                <div className="flex flex-col gap-y-1">
                    <Skeleton height={"138px"} />
                    <Skeleton height={"18px"} />
                    <Skeleton height={"100px"} />
                </div>
            </div>
        );
    }
    if (tableData.isError) {
        return <FundPageContent.P>There has been an error.</FundPageContent.P>;
    }

    return (
        <div>
            <FundPageContent.Header className="pb-2">
                {title}
            </FundPageContent.Header>
            <Table
                part={{
                    ...props.part,
                    data: tableData.data.data,
                    note: tableData.data.note,
                }}
                colDisplayDirectives={(i) => ({
                    alignRight: i > 0,
                })}
                colMeta={(i) => ({
                    cellParentClassName: cx(
                        i === 0 &&
                            "max-w-[150px] min-w-[150px] whitespace-normal sm:max-w-unset sm:min-w-0",
                    ),
                })}
                footNoteClassName="border-none"
            />
        </div>
    );
};
