import { GenericErrorBoundary } from "@hsl/lgim-explorer/src/components/GenericErrorBoundary/GenericErrorBoundary";

import GreyCurveContainer from "../../components/GreyCurveContainer";
import { NewsAndViewsCardListSkeleton } from "../../components/NewsAndViewsCard";
import useFundPageStore from "../../store/store";
import { Events, NewsAndViews } from "./components";

const HomeNewsAndViews = () => {
    const loading = useFundPageStore((store) => store.loading);

    if (loading) {
        return <NewsAndViewsCardListSkeleton className="-mt-12" />;
    }

    return (
        <GenericErrorBoundary>
            <GreyCurveContainer withCurve={false}>
                <Events />
                <NewsAndViews />
            </GreyCurveContainer>
        </GenericErrorBoundary>
    );
};

export default HomeNewsAndViews;
