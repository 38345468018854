import { z } from "zod";

import { dataPartSchema, postListItemSchema } from "@hsl/fund-page/schemas";

import FundPageContent from "../../../components/FundPageContent";
import { GreyCurve } from "../../../components/icons";
import NewsAndViewsCard from "../../../components/NewsAndViewsCard";
import usePageSection from "../../../hooks/usePageSection";
import useRelatedFunds from "../../../hooks/useRelatedFunds";

const articlesSchema = dataPartSchema.extend({
    data: z.array(postListItemSchema),
});

const Articles = () => {
    const { data, title } = usePageSection("articles", articlesSchema);

    const relatedFundIds = useRelatedFunds();

    const postsFilteredByFund = data.filter((post) => {
        const postFundIds = post.funds.map((x) => x.id);
        return relatedFundIds.some((x) => x && postFundIds.includes(x));
    });

    if (postsFilteredByFund.length === 0) {
        return null;
    }

    return (
        <div className="overflow-hidden print:hidden">
            <GreyCurve className="mx-auto" />
            <div className="bg-offWhite -mt-1 pb-6 pt-1">
                <div className="container mx-auto">
                    <FundPageContent.Header h2>{title}</FundPageContent.Header>
                    <div className="flex flex-col gap-6 lg:flex-row">
                        {postsFilteredByFund.slice(0, 3).map((post) => (
                            <NewsAndViewsCard key={post.id} {...post} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Articles;
