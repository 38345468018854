import { useEffect } from "react";
import { z } from "zod";

import useSticky from "@hsl/core/hooks/useSticky";
import { useActiveSectionContext } from "@hsl/fund-page/hooks/useActiveSection";
import { DocumentContextProvider } from "@hsl/fund-page/hooks/useDocumentContext";
import {
    dataPartSchema,
    formPartSchema,
    postListItemSchema,
} from "@hsl/fund-page/schemas";

import FundPageContent from "../../components/FundPageContent";
import Spinner from "../../components/Spinner";
import usePageSection from "../../hooks/usePageSection";
import useFundPageStore from "../../store/store";
import {
    authorsSchema,
    glossarySchema,
    importantInformationSchema,
    LatestQuarterlyReport,
    StickyBanner,
    TitleElem,
} from "./components";
import { ManagerDiaryContextProvider } from "./context";
import { ManagerDiaryDesktop, ManagerDiaryMobile } from "./views";

const diaryEntriesSchema = dataPartSchema.extend({
    part: z.object({
        data: z.object({
            custom_tag_categories:
                postListItemSchema.shape.custom_tag_categories,
            posts: z.array(
                postListItemSchema.omit({ custom_tag_categories: true }),
            ),
        }),
    }),
});

const managerDiarySchema = dataPartSchema.extend({
    canvas: dataPartSchema.extend({
        part: z
            .object({
                diaryEntries: diaryEntriesSchema,
                glossary: glossarySchema,
                importantInformation: importantInformationSchema,
                signUpForm: formPartSchema,
            })
            .merge(authorsSchema),
    }),
});

const DATA_KEY = "intheLoupe";

const ManagerDiary = () => {
    const { loading, isLiveData } = useFundPageStore((store) => store);
    const { stickyRef, stuck } = useSticky(loading || !isLiveData);
    const { setObserverDisabled } = useActiveSectionContext();
    const { audienceId, fundId, base_url } = useFundPageStore(
        (store) => store.config,
    );
    const { canvas, title, litApiId } = usePageSection(
        DATA_KEY,
        managerDiarySchema,
    );
    const {
        diaryEntries,
        glossary,
        importantInformation,
        signUpForm,
        diaryOverview,
        author,
    } = canvas.part;

    const contentProps = {
        author: author,
        diaryOverview: diaryOverview,
        glossary: glossary,
        importantInformation: importantInformation,
        formData: {
            fundName: signUpForm.part.data.fundName,
            partId: signUpForm.part.data.partVersionId,
        },
    };

    useEffect(() => {
        setObserverDisabled(true);
        return () => setObserverDisabled(false);
    }, [setObserverDisabled]);

    if (!isLiveData) {
        return <Spinner />;
    }

    return (
        <DocumentContextProvider
            queryKey="managerDiaryDocs"
            baseURL={base_url}
            litApiId={litApiId ?? -1}
            params={{ audience: audienceId, funds: fundId }}
        >
            <ManagerDiaryContextProvider
                diaryEntries={diaryEntries.part.data.posts}
                customTagCategories={
                    diaryEntries.part.data.custom_tag_categories
                }
            >
                <FundPageContent.Section
                    dataKey={DATA_KEY}
                    title={<TitleElem title={String(title)} />}
                    titleElem={<LatestQuarterlyReport />}
                    titleContainerClassName="sm:py-4"
                    className="block"
                >
                    <div
                        ref={stickyRef}
                        className="pointer-events-none relative -top-24 -z-50 sm:-top-36"
                    />
                    <StickyBanner stuck={stuck} title={title} />
                    <ManagerDiaryDesktop {...contentProps} />
                    <ManagerDiaryMobile {...contentProps} />
                </FundPageContent.Section>
            </ManagerDiaryContextProvider>
        </DocumentContextProvider>
    );
};

const ManagerDiaryWithErrorBoundary = () => {
    return (
        <FundPageContent.Wrapper dataKey={DATA_KEY}>
            <ManagerDiary />
        </FundPageContent.Wrapper>
    );
};

export default ManagerDiaryWithErrorBoundary;
