import { useEffect, useRef, useState } from "react";
import produce from "immer";
import { z } from "zod";

import { CollapsibleTransition } from "@hsl/components/Collapsible/components";
import {
    BreakpointProvider,
    useBreakpointContext,
} from "@hsl/core/hooks/useBreakpoint";
import {
    formDataSchema,
    PostListFilterData,
    postListFilterDataSchema,
    PostListFilterItem,
} from "@hsl/fund-page/schemas";
import { GenericErrorBoundary } from "@hsl/lgim-explorer/src/components/GenericErrorBoundary/GenericErrorBoundary";

import { Filter as FilterIcon } from "../../components/icons";
import SignUpForm from "../../components/SignUpForm";
import usePageSection from "../../hooks/usePageSection";
import useFundPageStore from "../../store/store";
import { Filters, NewsAndViewsSkeleton, PostList } from "./components";
import { NewsAndViewsContextProvider } from "./context";

const NewsAndViews = () => {
    const loading = useFundPageStore((store) => store.loading);

    if (loading) {
        return <NewsAndViewsSkeleton />;
    }

    return (
        <GenericErrorBoundary>
            <BreakpointProvider>
                <div className="bg-offWhite sm:px-0">
                    <div className="container mx-auto pt-8">
                        <Content />
                    </div>
                </div>
            </BreakpointProvider>
        </GenericErrorBoundary>
    );
};

export default NewsAndViews;

const newsAndViewsSchemaArticles = z.object({
    postApiId: z.number(),
});

const newsAndViewsFilterSchema = z.object({
    key: z.literal("filters"),
    partId: z.number(),
    data: postListFilterDataSchema,
});

const Content = () => {
    const { jurisdictionId, audienceId, usertypeId } = useFundPageStore(
        (store) => store.config,
    );
    const { postApiId } = usePageSection(
        "articles",
        newsAndViewsSchemaArticles,
    );
    const filters = usePageSection("filters", newsAndViewsFilterSchema);
    const isMobile = useBreakpointContext()?.currentBreakpoint === "xs";
    const [showFilters, setShowFilters] = useState(isMobile ? false : true);
    const toggleShowFilters = () => setShowFilters(!showFilters);

    const bottomOfList = useRef(null);
    const [fetchMorePosts, setFetchMorePosts] = useState(false);

    const computedFilters = computeFilters(filters.data);

    useEffect(() => {
        if (bottomOfList.current) {
            const observer = new IntersectionObserver((entries) => {
                if (
                    entries &&
                    entries.length > 0 &&
                    entries[0]?.isIntersecting
                ) {
                    setFetchMorePosts(true);
                } else {
                    setFetchMorePosts(false);
                }
            });
            observer.observe(bottomOfList.current);
        }
    }, []);

    return (
        <>
            <NewsAndViewsContextProvider
                postApiId={postApiId}
                numPostsToFetch={12}
                fetchMorePosts={fetchMorePosts}
                filters={computedFilters}
                config={{
                    jurisdictionId,
                    audienceId,
                    usertypeId,
                }}
            >
                <div className="flex flex-col flex-wrap items-stretch justify-between gap-y-4 sm:flex-row-reverse">
                    <div className="flex justify-between">
                        <button
                            className="flex items-center gap-2 sm:hidden"
                            onClick={toggleShowFilters}
                        >
                            <FilterIcon />
                            Filters
                        </button>
                        {/* <SignUpFormContent /> */}
                    </div>

                    <CollapsibleTransition
                        show={showFilters}
                        className="sm:flex-1"
                    >
                        <Filters filters={computedFilters} />
                    </CollapsibleTransition>
                </div>
                <PostList />
            </NewsAndViewsContextProvider>
            <div ref={bottomOfList} />
        </>
    );
};

const signUpFormContentSchema = z.object({
    data: formDataSchema,
});

const SignUpFormContent = () => {
    const { data } = usePageSection("signUp", signUpFormContentSchema);
    return (
        <SignUpForm
            className="ml-4 flex justify-end"
            buttonClassNames="!mt-0"
            fundName={data.fundName}
            partVersionId={data.partVersionId}
            label="Sign up for News and Views"
        />
    );
};

const computeFilters = (filters: PostListFilterData) => {
    return produce(filters, (state) => {
        state.funds = state.funds?.filter(
            (x) => !!x && Object.keys(x).length > 0,
        ) as PostListFilterItem[];
        state.contributors = state.contributors?.sort((a, b) =>
            a.name > b.name ? 1 : -1,
        );
    });
};
