import { z } from "zod";

import {
    DocumentContextProvider,
    useDocumentContext,
} from "@hsl/fund-page/hooks/useDocumentContext";
import { dataPartSchema } from "@hsl/fund-page/schemas";

import FundPageContent from "../../components/FundPageContent";
import usePageSection from "../../hooks/usePageSection";
import useFundPageStore from "../../store/store";
import { DocumentGroupList } from "./components";
import LiteratureSkeleton from "./components/LiteratureSkeleton";

const litSchema = dataPartSchema.extend({
    meta: z.object({
        keyDocsId: z.record(z.number()),
    }),
});

interface Props {
    dataKey: string;
}

const LitContent = ({ dataKey, keyDocsId }: Props & { keyDocsId: number }) => {
    const { docsData } = useDocumentContext();

    return (
        <FundPageContent.Section
            dataKey={dataKey}
            title={"Literature"}
            className="max-w-[792px] flex-col xl:flex-row"
        >
            {docsData.isLoading ? (
                <LiteratureSkeleton />
            ) : (
                <>
                    {/* Commented out Litrature Library on clients request */}
                    {/* <FundPageContent.Box containerClassName="flex-1">
                        <KeyDocsSection keyDocsSetId={keyDocsId} />
                    </FundPageContent.Box> */}

                    <DocumentGroupList keyDocsSetId={keyDocsId} />
                </>
            )}
        </FundPageContent.Section>
    );
};

const Literature = ({ dataKey }: Props) => {
    const { litApiId, meta } = usePageSection(dataKey, litSchema);
    const { audienceId, fundId, tenant, tenantURLMap } = useFundPageStore(
        (store) => store.config,
    );
    const baseURL = tenantURLMap[tenant!];
    const keyDocsId = Number(meta.keyDocsId[tenant]);
    return (
        <DocumentContextProvider
            baseURL={baseURL}
            litApiId={Number(litApiId)}
            params={{ audience: audienceId, funds: fundId }}
        >
            <LitContent dataKey={dataKey} keyDocsId={keyDocsId} />
        </DocumentContextProvider>
    );
};

const LiteratureWithErrorBoundary = (props: Props) => {
    return (
        <FundPageContent.Wrapper
            dataKey={props.dataKey}
            containerClassName="z-10"
        >
            <Literature {...props} />
        </FundPageContent.Wrapper>
    );
};

export default LiteratureWithErrorBoundary;
