import { createContext, useContext, useMemo, useState } from "react";

import usePostsQuery from "@hsl/core/fund-page/hooks/usePostsQuery";
import type {
    PostListFilterData,
    PostListFilterItem,
    PostListItem,
} from "@hsl/fund-page/schemas";

export type NewsAndViewsContextType = {
    activePosts?: PostListItem[];
    activeFund?: PostListFilterItem | null;
    activeContributor?: PostListFilterItem | null;
    activeCategory?: PostListFilterItem | null;
    reloadingIds: boolean;
    isLoadingMorePosts: boolean;
    numPostsToFetch?: number;
    filtersActive?: boolean;
    filters: PostListFilterData;
    setActiveFund(val: PostListFilterItem | null): void;
    setActiveContributor(val: PostListFilterItem | null): void;
    setActiveCategory(val: PostListFilterItem | null): void;
};

export interface Props {
    children: React.ReactNode;
    postApiId: number;
    numPostsToFetch: number;
    fetchMorePosts: boolean;
    filters: PostListFilterData;
    // if config is not present then will take from the session
    config?: {
        audienceId?: number;
        jurisdictionId?: number;
        usertypeId?: number;
    };
}

export const NewsAndViewsContext = createContext<
    NewsAndViewsContextType | undefined
>(undefined);

export const NewsAndViewsContextProvider = ({
    children,
    postApiId,
    numPostsToFetch,
    fetchMorePosts,
    filters,
    config,
}: Props) => {
    const params = getFilterParamsFromSearch(filters);
    const [activeContributor, setActiveContributor] =
        useState<PostListFilterItem | null>(params.contributor ?? null);
    const [activeFund, setActiveFund] = useState<PostListFilterItem | null>(
        (params.fund as PostListFilterItem) ?? null,
    );
    const [activeCategory, setActiveCategory] =
        useState<PostListFilterItem | null>(params.category ?? null);

    const { activePosts, isRefetchingIds, isLoadingMorePosts } = usePostsQuery({
        dataPartId: postApiId,
        config: {
            readRoute: 4160,
            metaTemplate: 2527,
            template: 2520,
            version: "live",
            numPostsToFetch,
            audienceId: config?.audienceId,
            jurisdictionId: config?.jurisdictionId,
            usertypeId: config?.usertypeId,
        },
        fund: activeFund?.id,
        category: activeCategory?.id,
        contributor: activeContributor?.id,
        fetchMorePosts: fetchMorePosts,
    });

    const contextValue = useMemo<NewsAndViewsContextType>(
        () => ({
            activePosts,
            activeFund,
            activeContributor,
            activeCategory,
            filtersActive:
                !!activeFund || !!activeContributor || !!activeCategory,
            reloadingIds: isRefetchingIds,
            filters,
            isLoadingMorePosts: isLoadingMorePosts,
            numPostsToFetch,
            setActiveContributor,
            setActiveFund,
            setActiveCategory,
        }),
        [
            activePosts,
            activeFund,
            activeContributor,
            activeCategory,
            numPostsToFetch,
            isRefetchingIds,
            isLoadingMorePosts,
            filters,
            setActiveContributor,
            setActiveFund,
            setActiveCategory,
        ],
    );

    return (
        <NewsAndViewsContext.Provider value={contextValue}>
            {children}
        </NewsAndViewsContext.Provider>
    );
};

export function useNewsAndViewsContext() {
    const documentContext = useContext(NewsAndViewsContext);
    if (!documentContext) throw new Error("No NewsAndViewsContextProvider");
    return documentContext;
}

function getFilterParamsFromSearch(filters: PostListFilterData) {
    const searchParams = new URL(String(document.location)).searchParams;

    const args = {
        contributor: filters.contributors?.find(
            (f) => f.id === searchParams.get("contributor"),
        ),
        fund: filters.funds?.find(
            (f) =>
                f && (f as PostListFilterItem).id === searchParams.get("fund"),
        ),
        category: filters.categories?.find(
            (f) => f.id === searchParams.get("category"),
        ),
    };
    return args;
}
