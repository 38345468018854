import { z } from "zod";

import {
    dataPartSchema,
    eventListPartSchema,
    postListPartSchema,
} from "@hsl/fund-page/schemas";

import FundPageContent from "../../components/FundPageContent";
import usePageSection from "../../hooks/usePageSection";
import { FundEventCard, NewsAndViewsCard, NewsAndViewsCTA } from "./components";

const newsAndViewsSchema = dataPartSchema.extend({
    canvas: dataPartSchema.extend({
        part: z.object({
            newsAndViews: postListPartSchema,
            events: eventListPartSchema,
        }),
    }),
});

interface Props {
    dataKey: string;
}

const NewsAndViews = ({ dataKey }: Props) => {
    const { canvas } = usePageSection(dataKey, newsAndViewsSchema);
    const { newsAndViews, events } = canvas.part;

    const featuredEvent = events.part.data[0];
    const featuredArticles = newsAndViews.part.data.slice(
        0,
        featuredEvent ? 2 : 3,
    );

    return (
        <FundPageContent.Section
            dataKey={dataKey}
            className="flex-col xl:flex-row"
        >
            <div className="flex flex-col justify-between">
                <FundPageContent.Header h3 className="text-white sm:mb-4">
                    {newsAndViews.title}
                </FundPageContent.Header>
                <div className="custom-scroller flex flex-1 snap-x flex-col gap-x-6 gap-y-4 sm:flex-row sm:overflow-x-auto xl:overflow-hidden">
                    {featuredArticles.map((article, i) => (
                        <NewsAndViewsCard
                            key={`${article.title}-${i}`}
                            {...article}
                        />
                    ))}
                </div>
                <NewsAndViewsCTA
                    href="/News-Views"
                    text="More news and views"
                />
            </div>
            {featuredEvent && (
                <div className="flex flex-col justify-between">
                    <FundPageContent.Header h3 className="text-white sm:mb-4">
                        {events.title}
                    </FundPageContent.Header>
                    <FundEventCard {...featuredEvent} />
                    <NewsAndViewsCTA href="/Events" text="More events" />
                </div>
            )}
        </FundPageContent.Section>
    );
};

const NewsAndViewsWithErrorBoundary = (props: Props) => {
    return (
        <FundPageContent.Wrapper dataKey={props.dataKey} withBg>
            <NewsAndViews {...props} />
        </FundPageContent.Wrapper>
    );
};

export default NewsAndViewsWithErrorBoundary;
