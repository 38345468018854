import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import { type DocumentSet } from "@hsl/documents/types";
import {
    DocumentContextProvider,
    useDocumentContext,
} from "@hsl/fund-page/hooks/useDocumentContext";
import { dataPartSchema } from "@hsl/fund-page/schemas";

import DocumentDownloadCTA from "../../../components/DocumentDownloadCTA";
import FundPageContent from "../../../components/FundPageContent";
import Skeleton from "../../../components/Skeletons";
import usePageSection from "../../../hooks/usePageSection";
import useFundPageStore from "../../../store/store";
import YearlyDateSelector from "./YearlyDateSelector";

interface Props {
    dataKey: string;
}

const ReportLibraryDocs = ({
    isLoading,
    activeDocs,
}: {
    isLoading: boolean;
    activeDocs: DocumentSet[];
}) => {
    if (isLoading || !activeDocs || activeDocs.length === 0) {
        return <DocSkeleton />;
    }
    return (
        <>
            {activeDocs.map(({ id, url, display_name, date }) => (
                <DocumentDownloadCTA
                    key={`esg-doc-${id}`}
                    href={url}
                    text={display_name}
                    date={dayjs(date).format("DD MMMM YYYY")}
                />
            ))}
        </>
    );
};

const ReportLibraryContent = ({
    dataKey,
    title,
}: Props & { title?: string }) => {
    const { docsData, isLoading } = useDocumentContext();
    const docSet = !!docsData.data ? docsData.data[0] : undefined;
    const [activeDate, setActiveDate] = useState<Date>();

    const dateFrequency = useMemo(() => {
        if (Array.isArray(docSet)) {
            return docSet[0].date_selector_frequency;
        } else {
            return null;
        }
    }, [docSet]);

    const activeDocs = useMemo(() => {
        if (!docSet) {
            return [];
        }
        if (!activeDate || !dateFrequency || !docSet[0].show_history) {
            return docSet[1];
        }
        return docSet[1].filter((doc) => {
            const docDate = new Date(doc.date);
            switch (dateFrequency) {
                case "yearly":
                    return docDate.getFullYear() === activeDate.getFullYear();
                default:
                    return true;
            }
        });
    }, [activeDate, docSet, dateFrequency]);

    useEffect(() => {
        if (!activeDate && docSet) {
            if (dateFrequency === "yearly") {
                const newActiveDate = new Date();
                newActiveDate.setFullYear(
                    Math.max(
                        ...docSet[1].map((doc) =>
                            new Date(doc.date).getFullYear(),
                        ),
                    ),
                );
                setActiveDate(newActiveDate);
            }
        }
    }, [docSet, dateFrequency]);

    return (
        <FundPageContent.Section dataKey={dataKey} className="flex-col">
            <div className="flex items-center justify-between">
                <FundPageContent.Header
                    h3
                    className="!mb-0 text-[30px] font-light"
                >
                    {title}
                </FundPageContent.Header>
                {dateFrequency === "yearly" &&
                    Array.isArray(docSet) &&
                    docSet[0].show_history &&
                    activeDate && (
                        <YearlyDateSelector
                            docs={docSet[1]}
                            value={activeDate}
                            onChange={setActiveDate}
                        />
                    )}
            </div>
            <FundPageContent.Box className="flex flex-col gap-y-4 rounded-xl">
                <ReportLibraryDocs
                    isLoading={Boolean(isLoading)}
                    activeDocs={activeDocs ?? []}
                />
            </FundPageContent.Box>
        </FundPageContent.Section>
    );
};

const ReportLibraryContainer = ({ dataKey }: Props) => {
    const { title, litApiId } = usePageSection(dataKey, dataPartSchema);
    const { audienceId, base_url } = useFundPageStore((store) => store.config);
    return (
        <DocumentContextProvider
            baseURL={base_url}
            litApiId={Number(litApiId)}
            params={{ audience: audienceId }}
        >
            <ReportLibraryContent dataKey={dataKey} title={title} />
        </DocumentContextProvider>
    );
};

const ReportLibraryWithErrorBoundary = (props: Props) => {
    return (
        <FundPageContent.Wrapper dataKey={props.dataKey} className="!pt-0">
            <ReportLibraryContainer {...props} />
        </FundPageContent.Wrapper>
    );
};

export default ReportLibraryWithErrorBoundary;

const DocSkeleton = () => <Skeleton width={"100%"} height={"275px"} />;

export const ReportLibrarySkeleton = () => (
    <div>
        <FundPageContent.HeaderSkeleton />
        <DocSkeleton />
    </div>
);
