import { useState, type ReactNode } from "react";
import dayjs from "dayjs";

import {
    LineChartPill,
    LineChartPills,
    usePerformanceLineChartContext,
    usePeriodOptions,
} from "@hsl/components/LineChart";
import {
    PERF_CHART_PERIOD_OPTIONS,
    PerformanceChartPeriod,
} from "@hsl/core/fund-page/schemas";
import { useBreakpointContext } from "@hsl/core/hooks/useBreakpoint";
import cx from "@hsl/core/utils/cx";

import useIsXS from "../../hooks/useIsXS";
import SpringDatePicker from "../DatePicker";
import { Calendar } from "../icons";

export const PerformanceLineChartDates = () => {
    const {
        startTime,
        endTime,
        apiData,
        loading,
        earliestStartDate,
        asAtDate,
        setStartTime,
        setEndTime,
        handlePeriodChange,
    } = usePerformanceLineChartContext();

    const isMobile = useBreakpointContext()?.currentBreakpoint === "xs";
    const isXS = useIsXS();

    const TEN_YEARS_AGO = dayjs(new Date()).subtract(10, "years").toDate();
    const [useCustomDateRange, setUseCustomDateRange] = useState(false);

    const significantPerformanceDate =
        apiData?.share_class_info.significant_performance_date;

    const periodOptions = usePeriodOptions({
        startTime,
        endTime,
        asAtDate: asAtDate,
        earliestStartDate: earliestStartDate,
        options: [...PERF_CHART_PERIOD_OPTIONS],
        activePeriods: apiData?.share_class_info.valid_periods.map(
            (x) => x[0] as unknown as PerformanceChartPeriod,
        ),
        labels: {
            sl: "Since launch",
            sspd: "Since launch",
        },
        shortLabels: {
            sl: isXS ? "SL" : "Since Launch",
            sspd: isXS ? "SL" : "Since Launch",
        },
        significantPerformanceDate: significantPerformanceDate
            ? new Date(significantPerformanceDate)
            : undefined,
    });

    if (!apiData || !earliestStartDate || !asAtDate) {
        return null;
    }

    return (
        <div className="flex min-w-[100%] flex-col items-center justify-between py-4 sm:flex-row">
            <div className="flex w-full items-center justify-between gap-x-1 pb-2 sm:w-auto sm:pb-0 sm:pt-4">
                <div className="flex items-center gap-x-1">
                    {periodOptions && (
                        <LineChartPills
                            options={periodOptions}
                            pillClassNames={{
                                default: "rounded-lg",
                                active: cx(
                                    !useCustomDateRange &&
                                        "bg-secondary text-white hover:bg-secondary/90",
                                ),
                            }}
                            disabled={loading}
                            onClick={(periodOption) => {
                                setUseCustomDateRange(false);
                                periodOption &&
                                    handlePeriodChange(periodOption);
                            }}
                        />
                    )}
                </div>
                <LineChartPill
                    isActive={useCustomDateRange}
                    className="h-[32px] rounded-lg px-6 sm:h-auto sm:px-3"
                    activeClassName="bg-secondary text-white hover:bg-secondary/90"
                    onClick={() => setUseCustomDateRange(true)}
                >
                    {isMobile ? <Calendar /> : "Custom"}
                </LineChartPill>
            </div>
            <div className="mb-2.5 flex w-full items-center gap-1 sm:mb-0 sm:w-auto">
                <span className="flex-1">
                    <DatePickerBrow>From</DatePickerBrow>
                    <SpringDatePicker
                        fromDate={
                            earliestStartDate > TEN_YEARS_AGO
                                ? earliestStartDate
                                : TEN_YEARS_AGO
                        }
                        toDate={endTime as Date}
                        value={startTime}
                        disabled={!useCustomDateRange}
                        loading={loading}
                        dayPickerClassName={cx(
                            !useCustomDateRange &&
                                "opacity-50 w-full sm:w-auto",
                        )}
                        Icon={Calendar}
                        onChange={setStartTime}
                    />
                </span>
                <span className="flex-1">
                    <DatePickerBrow>To</DatePickerBrow>
                    <SpringDatePicker
                        fromDate={startTime as Date}
                        toDate={asAtDate}
                        value={endTime}
                        disabled={!useCustomDateRange}
                        loading={loading}
                        dayPickerClassName={cx(
                            !useCustomDateRange &&
                                "opacity-50 w-full sm:w-auto",
                        )}
                        Icon={Calendar}
                        onChange={setEndTime}
                    />
                </span>
            </div>
        </div>
    );
};

const DatePickerBrow = ({ children }: { children: ReactNode }) => {
    return <div className="hidden pl-1 text-xs sm:block">{children}</div>;
};
