import { useState } from "react";
import { z } from "zod";

import { dataPartSchema, textPartSchema } from "@hsl/fund-page/schemas";

import FundPageContent from "../../../components/FundPageContent";
import InfoMessage from "../../../components/InfoMessage";
import { Tab, TabContainer } from "../../../components/Tabs";
import {
    ShareclassMetaTable,
    shareclassMetaTableSchema,
} from "../components/ShareclassMetaTable";
import ShareclassPricesAndCodesTable, {
    shareclassPricesTableSchema,
} from "../components/ShareclassPricesAndCodesTable";

export const pricesAndCodesSchema = dataPartSchema.extend({
    part: z.object({
        shareclassMeta: shareclassMetaTableSchema,
        shareclassCodes: shareclassPricesTableSchema,
        shareclassNote: textPartSchema.optional(),
    }),
});

export const PricesAndCodes = (props: z.infer<typeof pricesAndCodesSchema>) => {
    const { part, title } = props;
    const { shareclassMeta, shareclassCodes, shareclassNote } = part;

    const defaultGroup = Object.values(shareclassMeta.part.data).find(
        (scg) => scg?.isDefault === "True",
    );

    const shareClassGroupsWithData = Object.keys(shareclassCodes.part.data);

    if (!shareClassGroupsWithData) {
        throw Error("Prices and Codes - no share class groups have data");
    }

    const [activeGroup, setActiveGroup] = useState<string>(
        defaultGroup?.nameOne ?? (shareClassGroupsWithData[0] as string),
    );

    const sortedShareClassGroups = Object.values(shareclassMeta.part.data)
        .sort((a, b) => (a.nameOne > b.nameOne ? 1 : -1))
        .filter(({ nameOne }) => shareClassGroupsWithData.includes(nameOne));

    return (
        <>
            <FundPageContent.Header>{title}</FundPageContent.Header>
            <div
                className="flex flex-wrap items-center gap-2 pt-2"
                suppressHydrationWarning
            >
                <TabContainer
                    className="flex-wrap sm:flex-nowrap"
                    aria-label="Tabs"
                >
                    {sortedShareClassGroups.map(({ nameOne, nameTwo }) => {
                        return (
                            <Tab
                                key={nameOne}
                                isActive={activeGroup === nameOne}
                                text={`${nameOne}${
                                    nameTwo ? ` | ${nameTwo}` : ""
                                }`}
                                onClick={() => setActiveGroup(nameOne)}
                            />
                        );
                    })}
                </TabContainer>
                <div className="flex flex-col gap-y-2">
                    {sortedShareClassGroups.map(({ nameOne, note }) => {
                        if (!note || activeGroup !== nameOne) return null;
                        return (
                            <InfoMessage
                                severity="info"
                                html={note}
                                className="w-max max-w-[200px] rounded-xl py-1"
                                textClassName="leading-none"
                            />
                        );
                    })}
                </div>
            </div>
            <div className="my-4">
                <ShareclassMetaTable
                    activeGroup={activeGroup}
                    {...shareclassMeta}
                />
            </div>
            <ShareclassPricesAndCodesTable
                activeGroup={activeGroup}
                {...shareclassCodes}
            />
            {shareclassNote?.part.data && (
                <FundPageContent.Footnote
                    data={shareclassNote.part.data}
                    withBorder
                />
            )}
        </>
    );
};
