import { produce } from "immer";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { type PageSection } from "@hsl/core/fund-page/schemas";
import {
    getAllDataPartsFromConfig,
    getDataFromPart,
} from "@hsl/core/fund-page/services";
import { type Config } from "@hsl/spring-capital/src/js/fund-page/config";

import { config as defaultConfig } from "./default.config";
import processConfig from "./processConfig";
import { type FundPageState } from "./types";

const useFundPageStore = create<FundPageState>()(
    devtools(
        (set, get) =>
            ({
                config: defaultConfig,
                errors: [],
                messages: [],
                loading: true,
                isRefetching: false,
                translations: {},
                pageSections: [],
                pageSectionErrors: {},
                shareClasses: [],
                relatedFunds: [],
                modalOpen: false,
                /******************
                 * INIT
                 ******************/
                async init(config, initialData) {
                    console.log("%cCONFIG", "color: #007acc;", config);
                    try {
                        /************************
                         * SSR service
                         ************************/
                        console.log("INIT: ", { config, initialData });
                        if (initialData) {
                            console.log({ initialData });
                            set(
                                {
                                    ...get(),
                                    ...initialData,
                                    config: processConfig(initialData.config),
                                    isLiveData: false,
                                    loading: false,
                                },
                                false,
                                { type: "init: set static data" },
                            );

                            if (get().hasBeenInitialised) return;

                            if (typeof window === "undefined") return;

                            if (config.fundId) {
                                await get().setShareClasses();

                                await get().setRelatedFunds();
                            }

                            await get().setPageSections();

                            set(
                                produce((state: FundPageState) => {
                                    state.hasBeenInitialised = true;
                                    state.isLiveData = true;
                                }),
                                false,
                                {
                                    type: "init: complete client-side fetch",
                                },
                            );

                            return;
                        }

                        if (get().hasBeenInitialised) return;

                        /************************
                         * Client side service
                         ************************/
                        const {
                            setConfig,
                            setPageSections,
                            setShareClasses,
                            setRelatedFunds,
                        } = get();

                        setConfig(config);

                        if (config.fundId) {
                            await setShareClasses();

                            await setRelatedFunds();
                        }
                        await setPageSections();
                        set(
                            produce((state: FundPageState) => {
                                state.loading = false;
                                state.isLiveData = true;
                                state.hasBeenInitialised = true;
                            }),
                            false,
                            {
                                type: "init: complete client-side fetch (No SSR)",
                            },
                        );
                    } catch (error) {
                        console.log(
                            "%cerror INIT fund page store",
                            "color: red; display: block; width: 100%;",
                            error,
                        );
                    }
                },
                /******************
                 * SETTERS
                 ******************/
                setConfig(config: Config) {
                    const processedConfig = processConfig(config);
                    set(
                        produce((state: FundPageState) => {
                            state.config = processedConfig;
                        }),
                        false,
                        { type: "---CONFIG---" },
                    );
                },
                async setRelatedFunds() {
                    const {
                        relatedFunds,
                        audienceId,
                        routeId,
                        jurisdictionShortName,
                        usertypeShortName,
                    } = get().config;
                    // Get shareclass selector part data
                    const relatedFundsData = await getDataFromPart({
                        partId: relatedFunds,
                        audienceId,
                        routeId,
                        jurisdictionShortName,
                        usertypeShortName,
                    });
                    set(
                        produce((state: FundPageState) => {
                            state.relatedFunds = relatedFundsData.data;
                        }),
                        false,
                        { type: "---RELATED FUNDS---" },
                    );
                },
                async setShareClasses() {
                    const {
                        shareClassSelector,
                        tenant,
                        audienceId,
                        routeId,
                        base_url,
                        fundId,
                    } = get().config;
                    const selectorPartId = shareClassSelector[tenant];
                    if (selectorPartId) {
                        // Get shareclass selector part data
                        const shareClassSelectorData = await getDataFromPart({
                            partId: selectorPartId,
                            audienceId: audienceId,
                            routeId: routeId,
                            baseUrl: base_url,
                            fundID: fundId,
                        });
                        set(
                            produce((state: FundPageState) => {
                                state.shareClasses =
                                    shareClassSelectorData.data;
                            }),
                            false,
                            { type: "---SHARE CLASSES---" },
                        );
                    }
                },
                async setPageSections() {
                    const { config, shareClasses } = get();

                    const pageSections = await getAllDataPartsFromConfig(
                        {
                            ...config,
                            fundId:
                                config.tenant === "Spring"
                                    ? config.springFundId
                                    : config.fundId,
                        },
                        shareClasses[0]?.id,
                    );

                    set(
                        produce((state: FundPageState) => {
                            state.pageSections = pageSections.map(
                                (section) => ({
                                    ...section,
                                    title:
                                        section?.title ??
                                        state.config.pageSections.find(
                                            (x) => x.key === section?.key,
                                        )?.title,
                                }),
                            ) as PageSection[];
                        }),
                        false,
                        { type: "---PAGE SECTIONS---" },
                    );
                },
                setPageSectionError(key: string, error?: string) {
                    const current = get().pageSectionErrors[key];
                    if (current === error) return;
                    set(
                        produce((state: FundPageState) => {
                            if (error) {
                                state.pageSectionErrors[key] = error;
                            } else {
                                delete state.pageSectionErrors[key];
                            }
                        }),
                        false,
                        { type: `Error on section ${key}` },
                    );
                },
                setLoading(bool: boolean) {
                    set(
                        produce((state: FundPageState) => {
                            state.loading = bool;
                        }),
                        false,
                        { type: `Loading ${bool}` },
                    );
                },
                setModalOpen(bool: boolean) {
                    const { modalOpen } = get();
                    if (modalOpen !== bool) {
                        const pageContent =
                            document.querySelector("#page-content-zone");
                        pageContent?.classList.toggle("z-50");
                        set(
                            produce((state: FundPageState) => {
                                state.modalOpen = bool;
                            }),
                            false,
                            { type: `Modal open ${bool}` },
                        );
                    }
                },
            } as FundPageState),
        {
            name: "SpringFundPageStore",
            enabled: true,
        },
    ),
);

export default useFundPageStore;
