import { createContext, useContext, useMemo, useState } from "react";
import dayjs from "dayjs";
import { decode as htmlDecode } from "html-entities";

import { type PostListItem } from "@hsl/fund-page/schemas";

export type PostTypeObj = {
    name: string;
    color: string;
};

export type Company = {
    id: string;
    name: string;
};

export type ManagerDiaryContextType = {
    companies: Company[];
    postTypes: PostTypeObj[];
    activePostTypes: string[];
    activeCompanies: Company[];
    reverseOrder: boolean;
    activeDiaryEntries: Omit<PostListItem, "custom_tag_categories">[];
    setActivePostTypes: (vals: string[]) => void;
    setActiveCompanies: (vals: Company[]) => void;
    setReverseOrder: (bool: boolean) => void;
    clearFilters: () => void;
};

export interface Props {
    children: React.ReactNode;
    diaryEntries: Omit<PostListItem, "custom_tag_categories">[];
    customTagCategories: PostListItem["custom_tag_categories"];
}

export const ManagerDiaryContext = createContext<
    ManagerDiaryContextType | undefined
>(undefined);

export const ManagerDiaryContextProvider = ({
    children,
    diaryEntries,
    customTagCategories,
}: Props) => {
    const [activePostTypes, setActivePostTypes] = useState<string[]>([]);
    const [activeCompanies, setActiveCompanies] = useState<Company[]>([]);
    const [reverseOrder, setReverseOrder] = useState(false);

    const activeDiaryEntries = useMemo(() => {
        return diaryEntries
            .filter(
                (post) =>
                    activePostTypes.length === 0 ||
                    activePostTypes.includes(post.category_name),
            )
            .filter(
                (post) =>
                    activeCompanies.length === 0 ||
                    activeCompanies.some((company) =>
                        post.custom_tags?.some(
                            (postCompany) => postCompany.id === company.id,
                        ),
                    ),
            )
            .sort((a, b) => {
                if (reverseOrder) {
                    return dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1;
                } else {
                    return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1;
                }
            });
    }, [activePostTypes, activeCompanies, reverseOrder, diaryEntries]);

    const postTypes = useMemo<PostTypeObj[]>(
        () =>
            Array.from(
                new Set(
                    diaryEntries.map((post) =>
                        JSON.stringify({
                            name: post.category_name,
                            color: post.category_colour,
                        }),
                    ),
                ),
            ).map((json) => JSON.parse(json) as PostTypeObj),
        [diaryEntries],
    );

    const companies = useMemo<Company[]>(() => {
        const activeCustomTagIds = Array.from(
            new Set(
                diaryEntries.flatMap((post) =>
                    post.custom_tags.map(({ id }) => id),
                ),
            ),
        );
        const allCustomTags = diaryEntries.flatMap((post) =>
            post.custom_tags.map((customTag) => customTag),
        );
        const activeCustomTags = activeCustomTagIds.map((id) =>
            allCustomTags.find((customTag) => id === customTag.id),
        );
        const availableCompanyIds = customTagCategories["Companies"]
            ?.map((customTag) => customTag.id)
            .sort((a, b) => (Number(a) < Number(b) ? 1 : -1));

        return (activeCustomTags as Company[])
            .map((customTag) =>
                availableCompanyIds?.includes(customTag.id)
                    ? { name: htmlDecode(customTag.name), id: customTag.id }
                    : undefined,
            )
            .filter((x) => x !== undefined)
            .sort((a, b) =>
                String(a?.name) > String(b?.name) ? 1 : -1,
            ) as Company[];
    }, [customTagCategories, diaryEntries]);

    const contextValue = useMemo<ManagerDiaryContextType>(() => {
        const clearFilters = () => {
            setActivePostTypes([]);
            setActiveCompanies([]);
        };
        return {
            companies,
            postTypes,
            activeCompanies,
            activePostTypes,
            reverseOrder,
            activeDiaryEntries,
            setActiveCompanies,
            setActivePostTypes,
            setReverseOrder,
            clearFilters,
        };
    }, [
        companies,
        postTypes,
        activePostTypes,
        activeCompanies,
        reverseOrder,
        activeDiaryEntries,
        setActiveCompanies,
        setActivePostTypes,
        setReverseOrder,
    ]);

    return (
        <ManagerDiaryContext.Provider value={contextValue}>
            {children}
        </ManagerDiaryContext.Provider>
    );
};

export function useManagerDiaryContext() {
    const documentContext = useContext(ManagerDiaryContext);
    if (!documentContext) throw new Error("No ManagerDiaryContextProvider");
    return documentContext;
}
