import { z } from "zod";

import { peoplePartSchema, simplePartSchema } from "@hsl/fund-page/schemas";

import FundPageContent from "../../../components/FundPageContent";
import TextDataPart from "../../../components/TextDataPart";

export const authorsSchema = z.object({
    diaryOverview: simplePartSchema,
    author: peoplePartSchema,
});

export type AuthorsSchema = z.infer<typeof authorsSchema>;

const Authors = ({ diaryOverview, author }: AuthorsSchema) => {
    return (
        <>
            <FundPageContent.Header>
                {diaryOverview.part.data.title}
            </FundPageContent.Header>
            <ul className="flex flex-wrap gap-6 py-3">
                {author.part.data.map(({ headshot, firstName, lastName }) => {
                    const fullName = `${firstName} ${lastName}`;
                    return (
                        <li key={fullName} className="flex flex-col gap-y-2">
                            <img
                                src={headshot}
                                alt={fullName}
                                className="h-[84px] w-[84px] rounded-full"
                            />
                            <span className="font-semibold">{fullName}</span>
                        </li>
                    );
                })}
            </ul>
            <TextDataPart data={diaryOverview.part.data.text} />
        </>
    );
};

export default Authors;
