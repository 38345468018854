import { Fragment } from "react";
import { z } from "zod";

import Carousel from "@hsl/components/Carousel";
import cx from "@hsl/core/utils/cx";
import {
    advancedDataTableSchema,
    dataPartSchema,
} from "@hsl/fund-page/schemas";

const awardsSchema = dataPartSchema.extend({
    part: z.object({
        data: z.array(z.object({ link: z.string() })),
    }),
});

export const ribbonSchema = dataPartSchema.extend({
    part: z.object({
        fundSize: advancedDataTableSchema,
        fundLaunch: advancedDataTableSchema,
        fundHoldings: advancedDataTableSchema.optional(),
        fundReturn: advancedDataTableSchema.optional(),
        fundYield: advancedDataTableSchema.optional(),
        sfdr: advancedDataTableSchema.optional(),
        awards: awardsSchema.optional(),
    }),
});

type RibbonData = { header: string; data: string };
type RibbonContent = RibbonData[];

const Ribbon = ({ part }: z.infer<typeof ribbonSchema>) => {
    const {
        fundSize,
        fundLaunch,
        fundHoldings,
        fundReturn,
        fundYield,
        sfdr,
        awards,
    } = part;

    const ribbonDataParts = [
        fundSize,
        fundLaunch,
        fundHoldings,
        fundReturn,
        fundYield,
        sfdr,
    ]
        .sort((a, b) => (Number(a?.order) > Number(b?.order) ? 1 : -1))
        .map((x) => x?.part)
        .filter((x) => x !== undefined);

    const ribbonContent = ribbonDataParts
        .flatMap((part) => {
            return part?.headers?.map((header, i) => {
                if (!header || header.toLowerCase() === "key") {
                    return null;
                }
                return {
                    header,
                    data: part.data[0]![i],
                };
            });
        })
        .filter((x) => x !== null) as RibbonContent;

    const ribbonContentMobile = [
        ribbonContent.slice(0, 3),
        ribbonContent.slice(3),
    ];
    const ribbonContentOverTwoLines = ribbonContent.length > 3;

    const hasAwards = awards?.part.data && awards.part.data.length > 0;
    const fewerThanFiveAwards =
        awards?.part.data && awards?.part.data.length < 5;
    const centerAwards = !hasAwards && fewerThanFiveAwards;

    return (
        <div className="px-4 sm:px-0">
            <div className="flex flex-wrap items-center overflow-hidden rounded-xl bg-white xl:flex-nowrap">
                <div
                    className={cx(
                        "bg-secondary hidden flex-1 p-6 text-white xl:flex xl:justify-evenly",
                        centerAwards
                            ? "xl:justify-center"
                            : "xl:justify-evenly",
                        !hasAwards && "rounded-r-xl",
                    )}
                >
                    {ribbonContent.map((part, i) =>
                        renderRibbonContent(part, i, centerAwards),
                    )}
                </div>
                <div
                    className={cx(
                        "bg-secondary w-full p-4 text-white xl:hidden",
                        !hasAwards && "flex-1 rounded-r-xl",
                    )}
                >
                    <div
                        className={cx(
                            "grid grid-cols-3",
                            ribbonContentOverTwoLines &&
                                "border-b border-white/50 pb-2",
                        )}
                    >
                        {ribbonContentMobile[0]?.map((part, i) =>
                            renderRibbonContent(part, i, centerAwards),
                        )}
                    </div>
                    {ribbonContentOverTwoLines && (
                        <div className="grid grid-cols-3 pt-2">
                            {ribbonContentMobile[1]?.map((part, i) =>
                                renderRibbonContent(part, i, centerAwards),
                            )}
                        </div>
                    )}
                </div>
                {hasAwards && <Awards {...awards} />}
            </div>
        </div>
    );

    function renderRibbonContent(
        { header, data }: RibbonData,
        i: number,
        centerAwards?: boolean,
    ) {
        return (
            <Fragment key={`${header}-${i}`}>
                <RibbonDataPart header={header} data={data} />
                {i < ribbonContent.length - 1 && (
                    <Separator
                        className={cx(!centerAwards && "mx-8 xl:mx-12")}
                    />
                )}
            </Fragment>
        );
    }
};

export default Ribbon;

const Separator = ({ className }: { className?: string }) => (
    <span
        className={cx(
            "mx-4 hidden min-h-full min-w-[1px] max-w-[1px] bg-white/50 xl:mx-8 xl:block",
            className,
        )}
    />
);

const RibbonDataPart = ({ header, data }: { header: string; data: string }) => {
    return (
        <div className="mr-3 flex flex-col items-center justify-center border-r border-white/50 pr-3 last-of-type:mr-0 last-of-type:border-r-0 xl:mr-0 xl:border-r-0 xl:pr-0">
            <span className="w-max text-xs">{header}</span>
            <span className="w-max text-lg xl:text-xl">{data}</span>
        </div>
    );
};

const Awards = ({ part }: z.infer<typeof awardsSchema>) => {
    // Need to make a proper Carousel component at some point...
    return (
        <Carousel
            containerClassName="h-[92px]"
            className="gap-x-12"
            enabled={part.data.length > 2}
        >
            {part.data.map(({ link }) => {
                return (
                    <img
                        key={link}
                        src={link}
                        className="h-auto max-h-[56px] w-auto snap-center"
                    />
                );
            })}
        </Carousel>
    );
};
