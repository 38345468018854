import { useState } from "react";
import ArrowDownTray from "@heroicons/react/20/solid/ArrowDownTrayIcon";

import getFinancialQuarter from "@hsl/core/utils/getFinancialQuarter";
import { DocumentSet, DocumentSetStructure } from "@hsl/documents/types";
import { useDocumentContext } from "@hsl/fund-page/hooks/useDocumentContext";

import DocumentDownloadCTA from "../../../components/DocumentDownloadCTA";
import Dropdown from "../../../components/Dropdown";
import FundPageContent from "../../../components/FundPageContent";

const HistoricQuarterlyReportContent = ({
    setData,
    docsData,
}: {
    setData: DocumentSetStructure;
    docsData: DocumentSet[];
}) => {
    const [activeDoc, setActiveDoc] = useState<DocumentSet>(docsData[0]!);

    return (
        <div className="flex">
            <DocumentDownloadCTA
                textClassName="text-white"
                className="mb-0"
                iconContainerClassName="h-[82px] max-h-[82px] w-[73px] max-w-[73px]"
                href={activeDoc.url}
            />
            <div className="flex flex-col justify-center">
                <Dropdown<DocumentSet>
                    value={activeDoc}
                    options={docsData}
                    toLabel={(docSet) => {
                        const docDate = new Date(docSet.date);
                        const financialQuarter = getFinancialQuarter(docDate);
                        return `Q${financialQuarter} ${docDate.getFullYear()}`;
                    }}
                    onChange={setActiveDoc}
                />
                <div className="pt-1">
                    <FundPageContent.Header className="inline text-xl">
                        {setData.display_name}
                    </FundPageContent.Header>
                    <ArrowDownTray height={16} className="ml-1 inline" />
                </div>
            </div>
        </div>
    );
};

const HistoricQuarterlyReports = () => {
    const { docsData } = useDocumentContext();
    if (docsData.error || docsData.isLoading || !docsData.data) {
        return null;
    }
    const docSet = docsData.data[0];
    if (!docSet) {
        return null;
    }
    if (!docSet[0] || !docSet[1]) {
        return null;
    }
    return (
        <FundPageContent.Box>
            <HistoricQuarterlyReportContent
                setData={docSet[0]}
                docsData={docSet[1]}
            />
        </FundPageContent.Box>
    );
};

export default HistoricQuarterlyReports;
