import FundPageContent from "../../../components/FundPageContent";
import {
    Authors,
    Glossary,
    HistoricQuarterlyReports,
    ImportantInformation,
} from "../components";
import type {
    AuthorsSchema,
    GlossarySchema,
    ImportantInformationSchema,
} from "../components";

export interface AboutUsProps {
    author: AuthorsSchema["author"];
    diaryOverview: AuthorsSchema["diaryOverview"];
    glossary: GlossarySchema;
    importantInformation: ImportantInformationSchema;
}

const AboutUs = ({
    author,
    diaryOverview,
    glossary,
    importantInformation,
}: AboutUsProps) => {
    return (
        <div className="flex flex-col gap-y-4">
            <HistoricQuarterlyReports />

            <FundPageContent.Box>
                <Authors author={author} diaryOverview={diaryOverview} />
            </FundPageContent.Box>

            {glossary && (
                <FundPageContent.Box>
                    <Glossary {...glossary} />
                </FundPageContent.Box>
            )}

            <FundPageContent.Box>
                <FundPageContent.Header h4 className="mb-2">
                    {importantInformation.title}
                </FundPageContent.Header>
                <ImportantInformation {...importantInformation} />
            </FundPageContent.Box>
        </div>
    );
};

export default AboutUs;
