import { type ReactNode } from "react";

import ClientOnly from "@hsl/components/ClientOnly";
import { useBreakpointContext } from "@hsl/core/hooks/useBreakpoint";
import cx from "@hsl/core/utils/cx";

import { LineChartPeriodOption, PillClassNames } from "./types";

interface LineChartPillsProps {
    options: LineChartPeriodOption[];
    pillClassNames?: PillClassNames;
    disabled?: boolean;
    onClick(period?: LineChartPeriodOption): void;
}
export const LineChartPills = ({
    options,
    pillClassNames,
    disabled,
    onClick,
}: LineChartPillsProps) => {
    const isMobile = useBreakpointContext()?.currentBreakpoint === "xs";

    return options.map((periodOption) => {
        const { label, shortLabel, isActive, showOption } = periodOption;
        if (!showOption) {
            return null;
        }
        return (
            <ClientOnly key={label}>
                <LineChartPill
                    isActive={isActive}
                    disabled={disabled}
                    className={pillClassNames?.default}
                    activeClassName={pillClassNames?.active}
                    onClick={() => onClick(periodOption)}
                >
                    {isMobile ? shortLabel : label}
                </LineChartPill>
            </ClientOnly>
        );
    });
};
export const LineChartPill = ({
    children,
    isActive,
    disabled,
    className,
    activeClassName,
    onClick,
}: {
    children: ReactNode;
    isActive: boolean;
    disabled?: boolean;
    className?: string;
    activeClassName?: string;
    onClick?(args?: any): void;
}) => {
    return (
        <button
            className={cx(
                "px-3 py-1",
                "bg-gray-100 hover:bg-gray-200",
                "text-charcoal font-medium",
                "rounded-full transition",
                className,
                isActive && (activeClassName ?? "bg-gray-300"),
                {
                    "opacity-90": disabled,
                },
            )}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
};
