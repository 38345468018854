import cx from "@hsl/core/utils/cx";

import FundPageContent from "../../../components/FundPageContent";

export const DisclaimerText = ({ className }: { className?: string }) => {
    return (
        <FundPageContent.P className={cx("text-secondary", className)}>
            The content provided below is sourced from the fund managers. It's
            tailored to offer investors deeper insights into the investment
            decision-making process and illuminate recent portfolio adjustments.
        </FundPageContent.P>
    );
};

const Disclaimer = () => {
    return (
        <FundPageContent.Box className="mb-4">
            <DisclaimerText />
        </FundPageContent.Box>
    );
};

export default Disclaimer;
