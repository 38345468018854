import dayjs from "dayjs";

import FundPageContent from "../../../components/FundPageContent";
import { useManagerDiaryContext } from "../context";
import Disclaimer from "./Disclaimer";
import ManagerDiaryTextDataPart from "./ManagerDiaryTextDataPart";

const Posts = () => {
    const { activeDiaryEntries } = useManagerDiaryContext();

    return (
        <>
            <Disclaimer />
            <ul className="flex flex-col gap-y-6">
                {activeDiaryEntries?.map(
                    (
                        {
                            id,
                            title,
                            category_colour,
                            category_name,
                            date,
                            post_body,
                        },
                        i,
                    ) => {
                        return (
                            <li
                                key={`${title}-${date}-${i}`}
                                className="relative"
                            >
                                {/* Needed to account for scroll */}
                                <div
                                    id={`post-${id}`}
                                    className="absolute -top-48"
                                />
                                <FundPageContent.Box className="!p-0">
                                    <div
                                        className="flex items-center justify-between px-4 py-3 text-xl font-light text-white sm:rounded-t-lg sm:px-6"
                                        style={{
                                            backgroundColor: category_colour,
                                        }}
                                    >
                                        <FundPageContent.Header
                                            h3
                                            className="!mb-0 text-xl font-medium tracking-normal text-black"
                                        >
                                            {category_name}
                                        </FundPageContent.Header>
                                        <span className="text-xl font-medium text-black">
                                            {dayjs(date).format("D MMM YYYY")}
                                        </span>
                                    </div>
                                    <ManagerDiaryTextDataPart
                                        data={post_body}
                                    />
                                </FundPageContent.Box>
                            </li>
                        );
                    },
                )}
            </ul>
        </>
    );
};

export default Posts;
