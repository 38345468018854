import { z } from "zod";

import usePartData from "@hsl/fund-page/hooks/usePartData";
import {
    advancedDataTableData,
    AdvancedDataTableData,
    advancedDataTableSchema,
    dataPartSchema,
} from "@hsl/fund-page/schemas";
import FundPageContent from "@hsl/spring-fund-page/src/components/FundPageContent";

import InfoMessage from "../../../components/InfoMessage";
import { TableSkeleton } from "../../../components/Skeletons";
import Table from "../../../components/Table";
import useFundPageStore from "../../../store/store";
import { usePerformanceContext } from "../context";

export const statisticsSchema = dataPartSchema.extend({
    part: z.object({
        statsTable: advancedDataTableSchema,
        statsGlossary: dataPartSchema.extend({
            part: z.object({
                data: z.record(z.string()),
            }),
        }),
    }),
});

export const Statistics = (props: z.infer<typeof statisticsSchema>) => {
    const { activeShareClass } = usePerformanceContext();
    const config = useFundPageStore((store) => store.config);
    const { part, title } = props;
    const { statsTable, statsGlossary } = part;

    const tableData = usePartData<AdvancedDataTableData>(
        Number(statsTable.part.partId),
        {
            shareClassID: String(activeShareClass.id),
            audienceId: String(config.audienceId),
            baseUrl: config.base_url,
            routeId: String(config.routeId),
            languageId: String(config.languageId),
        },
        advancedDataTableData,
    );

    if (tableData.isLoading) {
        return <TableSkeleton numRows={8} />;
    }
    if (tableData.isError) {
        return <FundPageContent.P>There has been an error.</FundPageContent.P>;
    }

    return (
        <>
            <FundPageContent.Header>{title}</FundPageContent.Header>
            {tableData.data.data.length > 0 ? (
                <>
                    <Table
                        key={statsTable.key}
                        part={{
                            ...statsTable.part,
                            data: tableData.data.data,
                        }}
                        colDisplayDirectives={(i) => ({
                            alignRight: i > 0,
                            hideHeader: i === 0,
                        })}
                    />
                    <div>
                        <h4 className="my-2 text-lg font-semibold">Glossary</h4>
                        <ul>
                            {Object.keys(statsGlossary.part.data).map(
                                (word, i) => (
                                    <li
                                        key={`${word}-${i}`}
                                        className="mb-2 text-sm last-of-type:mb-0 sm:text-base"
                                    >
                                        <span className="text-purple font-semibold">
                                            {word}{" "}
                                        </span>
                                        <span>
                                            {statsGlossary.part.data[word]}
                                        </span>
                                    </li>
                                ),
                            )}
                        </ul>
                    </div>
                </>
            ) : (
                <InfoMessage
                    severity="info"
                    html="As the selected share class is less than 3 years old, no statistics are shown"
                    className="mt-4"
                />
            )}
        </>
    );
};
