import { z } from "zod";

import ClientOnly from "@hsl/components/ClientOnly";
import {
    advancedDataTableSchema,
    dataPartSchema,
} from "@hsl/fund-page/schemas";

import FundPageContent from "../../components/FundPageContent";
import usePageSection from "../../hooks/usePageSection";
import {
    DividendHistory,
    dividendHistorySchema,
} from "./views/DividendHistory";
import { IncomeAndTaxStatus } from "./views/IncomeAndTaxStatus";
import { PricesAndCodes, pricesAndCodesSchema } from "./views/PricesAndCodes";
import { PricesHistory, pricesHistorySchema } from "./views/PricesHistory";

const pricesAndDividendsSectionSchema = dataPartSchema.extend({
    canvas: dataPartSchema.extend({
        part: z.object({
            pricesAndCodes: pricesAndCodesSchema,
            incomeAndTaxStatus: advancedDataTableSchema.optional(),
            dividendHistory: dividendHistorySchema.optional(),
            priceHistory: pricesHistorySchema,
        }),
    }),
});

interface Props {
    dataKey: string;
}

const PricesAndDividends = ({ dataKey }: Props) => {
    const { canvas, title } = usePageSection(
        dataKey,
        pricesAndDividendsSectionSchema,
    );
    const {
        pricesAndCodes,
        incomeAndTaxStatus,
        dividendHistory,
        priceHistory,
    } = canvas.part;

    return (
        <FundPageContent.Section
            dataKey={dataKey}
            title={title}
            className="flex w-full min-w-full flex-col gap-6"
        >
            <div className="flex flex-col gap-x-6 gap-y-4 xl:flex-row xl:items-stretch">
                <FundPageContent.Box
                    containerClassName="flex-[2]"
                    className="h-full"
                >
                    <PricesAndCodes {...pricesAndCodes} />
                </FundPageContent.Box>
                <FundPageContent.Box containerClassName="flex-[1] w-full">
                    <ClientOnly>
                        <PricesHistory {...priceHistory} />
                    </ClientOnly>
                </FundPageContent.Box>
            </div>
            <div className="flex w-full min-w-full flex-col gap-x-6 gap-y-4 xl:flex-row xl:items-stretch">
                {incomeAndTaxStatus && (
                    <FundPageContent.Box
                        containerClassName="flex-1 xl:max-w-[33%]"
                        className="h-full w-full xl:w-max"
                    >
                        <IncomeAndTaxStatus {...incomeAndTaxStatus} />
                    </FundPageContent.Box>
                )}
                {dividendHistory && (
                    <FundPageContent.Box containerClassName="flex-[2] w-full">
                        <DividendHistory {...dividendHistory} />
                    </FundPageContent.Box>
                )}
            </div>
        </FundPageContent.Section>
    );
};

const PricesAndDividendsWithErrorBoundary = (props: Props) => {
    return (
        <FundPageContent.Wrapper dataKey={props.dataKey}>
            <PricesAndDividends {...props} />
        </FundPageContent.Wrapper>
    );
};

export default PricesAndDividendsWithErrorBoundary;
