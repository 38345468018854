import dayjs from "dayjs";
import { z } from "zod";

import cx from "@hsl/core/utils/cx";
import {
    advancedDataTableSchema,
    dataPartSchema,
    documentPartDataSchema,
    textCollectionPartArraySchema,
} from "@hsl/fund-page/schemas";

import DocumentDownloadCTA, {
    type Props as DocumentDownloadCTAProps,
} from "../../components/DocumentDownloadCTA";
import FundPageContent from "../../components/FundPageContent";
import usePageSection from "../../hooks/usePageSection";
import CommentaryText from "./components/CommentaryText";
import GraphSection, {
    geographicAllocationSchema,
    marketCapAllocationSchema,
    noteSchema,
    sectorAllocationSchema,
} from "./views/GraphSection";
import { TopHoldings, topHoldingsSchema } from "./views/TopHoldings";

const commentaryAndPortfolioSectionSchema = dataPartSchema.extend({
    canvas: dataPartSchema.extend({
        part: z.object({
            commentary: textCollectionPartArraySchema,
            topHoldings: topHoldingsSchema.optional(),
            monthlyFactSheet: documentPartDataSchema,
            sectorAllocation: sectorAllocationSchema.optional(),
            geographicAllocation: geographicAllocationSchema.optional(),
            marketCapAllocation: marketCapAllocationSchema.optional(),
            note: noteSchema.optional(),
            aum: advancedDataTableSchema,
        }),
    }),
});

interface Props {
    dataKey: string;
}

const CommentaryAndPortfolio = ({ dataKey }: Props) => {
    const { canvas, title } = usePageSection(
        dataKey,
        commentaryAndPortfolioSectionSchema,
    );
    const {
        commentary,
        topHoldings,
        sectorAllocation,
        geographicAllocation,
        marketCapAllocation,
        monthlyFactSheet,
        note,
        aum,
    } = canvas.part;

    const subtitle = monthlyFactSheet
        ? `As at ${dayjs(monthlyFactSheet.part.data[0]?.asAtDate).format(
              "DD MMMM YYYY",
          )}`
        : undefined;

    return (
        <FundPageContent.Section
            dataKey={dataKey}
            title={title}
            subTitle={subtitle}
            titleElem={
                <>
                    <HeaderElemDesktop />
                    <HeaderElemMobile />
                </>
            }
            titleClassName="flex-col md:flex-row text-center md:text-left"
            titleContainerClassName="xl:py-4"
            titleElemContainerClassName="w-full md:w-auto"
        >
            <div className="flex max-w-full flex-col gap-y-6">
                <div className="flex max-w-full flex-wrap gap-x-6 gap-y-4 xl:flex-nowrap">
                    <FundPageContent.Box>
                        {commentary.title && (
                            <FundPageContent.Header>
                                {commentary.title}
                            </FundPageContent.Header>
                        )}
                        <CommentaryText {...commentary} />
                    </FundPageContent.Box>
                    {topHoldings && (
                        <FundPageContent.Box
                            className="max-w-full xl:min-w-[400px]"
                            containerClassName="flex-[2]"
                        >
                            <TopHoldings {...topHoldings} />
                        </FundPageContent.Box>
                    )}
                </div>
                {geographicAllocation &&
                    marketCapAllocation &&
                    sectorAllocation && (
                        <GraphSection
                            geographicAllocation={geographicAllocation}
                            marketCapAllocation={marketCapAllocation}
                            sectorAllocation={sectorAllocation}
                            note={note}
                        />
                    )}
            </div>
        </FundPageContent.Section>
    );

    function DocumentDownload({
        iconClassName,
        textClassName,
        iconContainerClassName,
    }: Pick<
        DocumentDownloadCTAProps,
        "iconClassName" | "textClassName" | "iconContainerClassName"
    >) {
        return (
            <DocumentDownloadCTA
                text={monthlyFactSheet?.title}
                textClassName={cx(
                    "text-white font-normal sm:text-base",
                    textClassName,
                )}
                color="white"
                className="mb-0"
                iconClassName={cx("h-[44px]", iconClassName)}
                iconContainerClassName={cx("mr-0", iconContainerClassName)}
                href={monthlyFactSheet?.part.data[0]?.href}
            />
        );
    }

    function HeaderElemDesktop() {
        return (
            <span className="absolute bottom-0 right-12 top-0 hidden items-center md:flex">
                <div className="border-offwhite mr-12 flex h-full flex-col items-center justify-center border-l border-r px-12">
                    <div className="text-xs font-semibold">
                        {aum.part.headers![1]}
                    </div>
                    <div className="text-2xl">{aum.part.data[0]![1]}</div>
                </div>
                <DocumentDownload />
            </span>
        );
    }

    function HeaderElemMobile() {
        return (
            <div className="border-offWhite/30 flex items-center justify-center border-t pt-2 text-left md:hidden">
                <div className="border-offWhite/30 mr-4 border-r pr-4">
                    <div className="text-xs font-semibold">
                        {aum.part.headers![1]}
                    </div>
                    <div className="text-xl md:text-2xl">
                        {aum.part.data[0]![1]}
                    </div>
                </div>
                <DocumentDownload
                    iconClassName="h-[33px] w-[30px]"
                    iconContainerClassName="max-h-[50px] sm:max-h-[50px] mr-2"
                    textClassName="text-sm sm:text-sm !mb-0"
                />
            </div>
        );
    }
};

const CommentaryAndPortfolioWithErrorBoundary = (props: Props) => {
    return (
        <FundPageContent.Wrapper dataKey={props.dataKey}>
            <CommentaryAndPortfolio {...props} />
        </FundPageContent.Wrapper>
    );
};

export default CommentaryAndPortfolioWithErrorBoundary;
