import { useCallback } from "react";

import Collapsible from "@hsl/components/Collapsible";

import { useManagerDiaryContext, type Company } from "../../context";
import { FilterCheckbox, FilterHeader, FilterSection } from "./FilterContent";

export const CompanyFilters = () => {
    const { companies, activeCompanies, setActiveCompanies } =
        useManagerDiaryContext();

    const renderCompanyFilter = useCallback(
        (company: Company) => {
            if (!company) {
                return null;
            }
            const isSelected = activeCompanies.includes(company);
            return (
                <FilterCheckbox<Company>
                    key={`${company}-filter`}
                    value={company}
                    onChange={(val) => {
                        if (activeCompanies.includes(val)) {
                            setActiveCompanies(
                                activeCompanies.filter((x) => x.id !== val.id),
                            );
                        } else {
                            setActiveCompanies([...activeCompanies, val]);
                        }
                    }}
                    selected={isSelected}
                    label={company.name}
                />
            );
        },
        [activeCompanies, setActiveCompanies],
    );

    return (
        <FilterSection>
            <FilterHeader>Companies</FilterHeader>
            {companies.slice(0, 5).map(renderCompanyFilter)}
            {companies.length > 5 && (
                <Collapsible
                    labelPosition="end"
                    label={(open) => `Show ${open ? "less" : "more"}`}
                    labelClassNames="pt-2 font-semibold"
                    withChevron
                >
                    {companies.slice(5).map(renderCompanyFilter)}
                </Collapsible>
            )}
        </FilterSection>
    );
};
