import cx from "@hsl/core/utils/cx";
import usePartData from "@hsl/fund-page/hooks/usePartData";
import {
    advancedDataTableData,
    AdvancedDataTableData,
    AdvancedDataTableSchema,
} from "@hsl/fund-page/schemas";
import FundPageContent from "@hsl/spring-fund-page/src/components/FundPageContent";

import { TableSkeleton } from "../../../components/Skeletons";
import Table from "../../../components/Table";
import useFundPageStore from "../../../store/store";
import { usePerformanceContext } from "../context";

export const PerformanceSummaryTable = (props: AdvancedDataTableSchema) => {
    const { activeShareClass } = usePerformanceContext();
    const { audienceId, base_url, routeId, languageId } = useFundPageStore(
        (store) => store.config,
    );

    const tableData = usePartData<AdvancedDataTableData>(
        Number(props.part.partId),
        {
            shareClassID: String(activeShareClass.id),
            audienceId: String(audienceId),
            baseUrl: base_url,
            routeId: String(routeId),
            languageId: String(languageId),
        },
        advancedDataTableData,
    );

    if (tableData.isLoading) {
        return <TableSkeleton numRows={4} />;
    }
    if (tableData.isError) {
        return <FundPageContent.P>There has been an error.</FundPageContent.P>;
    }

    return (
        <Table
            part={{
                ...props.part,
                data: createSectorRankingRow(tableData.data.data),
            }}
            colDisplayDirectives={(i) => ({
                alignRight: i > 0,
            })}
            colMeta={(i) => ({
                headerParentClassName: cx(
                    "bg-offWhite",
                    i === 3 && "bg-gapRight pr-4",
                    i === 4 && "bg-gapLeft pl-4",
                    "border-b-0",
                ),
                cellParentClassName: cx(
                    i === 0 &&
                        "max-w-[150px] min-w-[150px] whitespace-normal sm:max-w-unset sm:min-w-0",
                    i === 3 && "pr-4",
                    i === 4 && "pl-4",
                ),
            })}
        />
    );
};

function createSectorRankingRow(data: (string | null)[][]) {
    const fundAndIASector = data.slice(0, 2);
    const sectorRanking = data[2]!;
    const fundsInSector = data[3]!;
    const quartile = data[4];
    return [
        ...fundAndIASector,
        sectorRanking.map((x, i) => {
            if (i === 0) return x;
            return `${x}/${fundsInSector[i]}`;
        }),
        quartile,
    ] as string[][];
}
