import cx from "@hsl/core/utils/cx";
import getFinancialQuarter from "@hsl/core/utils/getFinancialQuarter";
import { useDocumentContext } from "@hsl/fund-page/hooks/useDocumentContext";

import DocumentDownloadCTA, {
    type Props,
} from "../../../components/DocumentDownloadCTA";

const LatestQuarterlyReport = ({
    textClassName,
    iconContainerClassName,
}: Pick<Props, "textClassName" | "iconContainerClassName">) => {
    const { docsData } = useDocumentContext();
    if (docsData.error || docsData.isLoading || !docsData.data) {
        return null;
    }
    const docSet = docsData.data[0];
    if (!docSet) {
        return null;
    }
    const setData = docSet[0];
    const docData = docSet[1][0];
    if (!setData || !docData) {
        return null;
    }

    const docDate = new Date(docData.date);
    const financialQuarter = getFinancialQuarter(docDate);

    return (
        <DocumentDownloadCTA
            textClassName={cx(
                "text-2xs w-min sm:w-auto font-extralight",
                textClassName,
            )}
            color="white"
            className="mb-0 ml-2"
            iconContainerClassName={cx(
                "mr-1 sm:mr-4 max-w-[32px]",
                iconContainerClassName,
            )}
            href={docData?.url}
            text={
                <>
                    Q{financialQuarter} {docDate.getFullYear()}
                    <br />
                    {setData.display_name}
                </>
            }
        />
    );
};

export default LatestQuarterlyReport;
