import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { ZodObject } from "zod";

import type { AvailableDatesPartSchema, Config } from "../schemas";
import usePartData from "./usePartData";

interface Props {
    pricePartId: number;
    availableDatesPart: AvailableDatesPartSchema;
    schema: ZodObject<any>;
    config: Pick<
        Config,
        | "audienceId"
        | "base_url"
        | "jurisdictionId"
        | "languageId"
        | "routeId"
        | "fundId"
    >;
}

function usePriceQuery<T>({
    pricePartId,
    availableDatesPart,
    schema,
    config,
}: Props) {
    const dateRange = useMemo(() => {
        type Datum = (typeof availableDatesPart.part.data)[0] | undefined;
        const today = new Date();
        const getItem = <T,>(arr: T[], i: number) =>
            i > 0 ? arr[i] : arr.slice(i)[0];
        const monthFormatter = new Intl.DateTimeFormat("en-GB", {
            month: "2-digit",
        });

        const priceHistoryData = availableDatesPart.part.data;
        const datumFirst = getItem(priceHistoryData, 0);
        const datumLast = getItem(priceHistoryData, -1);

        const getYear = (x: Datum) =>
            x?.value ? `${x.value}` : `${today.getFullYear()}`;
        const getMonth = (x: Datum, i: number) =>
            x?.availableMonths
                ? `${getItem(x.availableMonths, i)?.value}`
                : monthFormatter.format(today);
        const getDay = (x: Datum, i: number) => {
            const defaultDay = today.getDate();
            if (!x) {
                return defaultDay;
            }
            const month = getItem(x.availableMonths, i);
            if (!month) {
                return defaultDay;
            }
            return getItem(month.availableDays, i) ?? defaultDay;
        };

        const fromDateStr = `${getYear(datumFirst)}-${getMonth(
            datumFirst,
            0,
        )}-${getDay(datumFirst, 0)}`;
        const toDateStr = `${getYear(datumLast)}-${getMonth(
            datumLast,
            -1,
        )}-${getDay(datumLast, -1)}`;
        const fromDate = dayjs(fromDateStr).toDate();
        const toDate = dayjs(toDateStr).toDate();
        return {
            fromDate,
            toDate,
        };
    }, [availableDatesPart]);

    const [activeDate, setActiveDate] = useState<Date | undefined>();

    useEffect(() => {
        setActiveDate(dateRange.toDate);
    }, [dateRange]);

    const PriceQuery = usePartData<T>(
        Number(pricePartId),
        {
            audienceId: String(config.audienceId),
            baseUrl: config.base_url,
            fundId: String(config.fundId),
            jurisdictionId: String(config.jurisdictionId),
            languageId: String(config.languageId),
            routeId: String(config.routeId),
            pricePartDay: dayjs(activeDate).format("D"),
            pricePartMonth: dayjs(activeDate).format("M"),
            pricePartYear: dayjs(activeDate).format("YYYY"),
        },
        schema,
    );

    return {
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
        PriceQuery,
        activeDate,
        setActiveDate,
    };
}

export default usePriceQuery;
