import { useMemo } from "react";
import { z } from "zod";

import { convertAvailableDates } from "@hsl/core/fund-page/utils/convertAvailableDates";
import usePriceQuery from "@hsl/fund-page/hooks/usePriceQuery";
import {
    availableDatesPartSchema,
    dataPartSchema,
} from "@hsl/fund-page/schemas";

import SpringDatePicker from "../../../components/DatePicker";
import FundPageContent from "../../../components/FundPageContent";
import useFundPageStore from "../../../store/store";
import {
    PricesHistoryTable,
    pricesHistoryTableDataSchema,
    pricesHistoryTableSchema,
    type PricesHistoryTableDataSchema,
} from "../components/PriceHistoryTable";

export const pricesHistorySchema = dataPartSchema.extend({
    part: z.object({
        priceHistoryTable: pricesHistoryTableSchema,
        priceHistoryDateSelector: availableDatesPartSchema,
    }),
});

export const PricesHistory = (props: z.infer<typeof pricesHistorySchema>) => {
    const config = useFundPageStore((store) => store.config);
    const isLiveData = useFundPageStore((store) => store.isLiveData);
    const { part, title } = props;
    const { priceHistoryTable, priceHistoryDateSelector } = part;

    const { PriceQuery, fromDate, toDate, activeDate, setActiveDate } =
        usePriceQuery<PricesHistoryTableDataSchema>({
            pricePartId: priceHistoryTable.part.partId,
            availableDatesPart: priceHistoryDateSelector,
            config: config,
            schema: pricesHistoryTableDataSchema,
        });

    const availableDatesArr = useMemo(() => {
        return convertAvailableDates(priceHistoryDateSelector.part.data);
    }, [priceHistoryDateSelector]);

    return (
        <>
            <div className="mb-4 flex items-center justify-between sm:mb-2">
                <FundPageContent.Header className="!mb-0">
                    {title}
                </FundPageContent.Header>
                <SpringDatePicker
                    fromDate={fromDate}
                    toDate={toDate}
                    value={activeDate}
                    disabled={false}
                    panelClassName="right-4"
                    disabledDays={(date) =>
                        !availableDatesArr.some(
                            (d) => d.valueOf() === date.valueOf(),
                        )
                    }
                    onChange={setActiveDate}
                />
            </div>
            <PricesHistoryTable
                {...PriceQuery.data!}
                isLoading={!isLiveData || PriceQuery.isLoading}
            />
        </>
    );
};
