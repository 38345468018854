import React, {
    useId,
    type ChangeEventHandler,
    type CSSProperties,
    type MouseEventHandler,
} from "react";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";

import cx from "@hsl/core/utils/cx";

export interface Props<V> {
    containerClassName?: string;
    inputClassName?: string;
    inputStyles?: CSSProperties;
    labelClassName?: string;
    label?: React.ReactNode;
    checkIconClassName?: string;
    value: V;
    selected?: boolean;
    disabled?: boolean;
    error?: boolean;
    onChange?(val: V, checked: boolean): void;
}

const Checkbox = <V,>({
    containerClassName,
    inputClassName,
    inputStyles,
    labelClassName,
    label,
    checkIconClassName,
    selected,
    value,
    disabled,
    error,
    onChange,
}: Props<V>) => {
    const id = useId();

    const handleChange: MouseEventHandler<HTMLElement> = (e) => {
        if (!disabled) {
            onChange && onChange(value, !Boolean(selected));
        }
    };

    return (
        <div className={cx("relative flex items-center", containerClassName)}>
            <span
                id={id}
                className={cx(
                    "h-4 w-4 cursor-pointer rounded border border-gray-300",
                    inputClassName,
                    error && "border-red-400",
                )}
                style={inputStyles}
                role="checkbox"
                aria-checked={selected}
                onClick={handleChange}
            >
                {selected && <CheckIcon className={checkIconClassName} />}
            </span>
            {label && (
                <div className="min-w-0 flex-1 text-sm leading-4">
                    <label
                        htmlFor={id}
                        className={cx(
                            "cursor-pointer select-none whitespace-break-spaces font-normal",
                            labelClassName,
                            disabled && "opacity-50",
                        )}
                        onClick={handleChange}
                    >
                        {label}
                    </label>
                </div>
            )}
        </div>
    );
};

export default Checkbox;
