import cx from "@hsl/core/utils/cx";
import { isLight } from "@hsl/core/utils/isLight";

import { useManagerDiaryContext } from "../../context";
import { FilterCheckbox, FilterHeader, FilterSection } from "./FilterContent";

export const PostTypeFilters = () => {
    const { postTypes, activePostTypes, setActivePostTypes } =
        useManagerDiaryContext();

    return (
        <FilterSection>
            <FilterHeader>Post type</FilterHeader>
            {postTypes.map(({ name, color }) => {
                const isSelected = activePostTypes.includes(name);
                return (
                    <FilterCheckbox<string>
                        key={`${name}-filter`}
                        value={name}
                        onChange={(val) => {
                            if (activePostTypes.includes(val)) {
                                setActivePostTypes(
                                    activePostTypes.filter((x) => x !== val),
                                );
                            } else {
                                setActivePostTypes([...activePostTypes, val]);
                            }
                        }}
                        selected={isSelected}
                        inputClassName={cx(
                            !isSelected && "border-[3px]",
                            "mr-1 rounded-none",
                        )}
                        checkIconClassName={
                            isLight(color) ? "text-black" : "text-white"
                        }
                        inputStyles={{
                            borderColor: color,
                            backgroundColor: isSelected ? color : "transparent",
                        }}
                        labelClassName="font-bold"
                        label={name}
                    />
                );
            })}
        </FilterSection>
    );
};
