import Collapsible from "@hsl/components/Collapsible";
import { groupByDocType } from "@hsl/core/documents/helpers/groupers";
import cx from "@hsl/core/utils/cx";
import { useDocumentContext } from "@hsl/fund-page/hooks/useDocumentContext";

import FundPageContent from "../../../components/FundPageContent";
import { DocumentListItem } from "./DocumentListItem";
import { DocumentListItemWithDatePicker } from "./DocumentListItemWithDatePicker";
import { DocumentListItemWithShareClassSelector } from "./DocumentListItemWithShareClassSelector";

export const DocumentGroupList = ({
    keyDocsSetId,
}: {
    keyDocsSetId: number;
}) => {
    const { docsData, baseURL } = useDocumentContext();
    if (!docsData.data) {
        return null;
    }
    const groupData = docsData.data.filter((x) => x[0].set_id !== keyDocsSetId);
    if (!groupData) {
        return null;
    }

    return (
        <>
            <ul className="z-0 flex w-full flex-[2] flex-col gap-y-2">
                {groupData.map(([set, docs], i) => {
                    const {
                        display_name,
                        set_id,
                        date_selector_frequency,
                        show_history,
                        settings,
                    } = set;

                    const docList = groupByDocType(docs);

                    return (
                        <Collapsible
                            isOpen={i === 0}
                            as="li"
                            key={`set-${set_id}`}
                            withChevron
                            labelClassNames={(open) =>
                                cx(
                                    "w-full justify-between items-center p-4 sm:p-6",
                                    open && "border-b-2 border-b-offWhite",
                                )
                            }
                            chevronWidth={32}
                            className="relative bg-white sm:rounded-xl"
                            style={{ zIndex: 500000 - i }}
                            label={
                                <FundPageContent.Header className="!mb-0">
                                    <span className="mr-4">{display_name}</span>
                                    <span className="text-sm font-normal">
                                        {settings?.label}
                                    </span>
                                </FundPageContent.Header>
                            }
                        >
                            <ul className="p-4 sm:p-6">
                                {Object.values(docList).map((docArr, j) => {
                                    if (!docArr) return null;
                                    const languagesInDocList = Array.from(
                                        new Set(
                                            docArr.map((doc) => doc.language),
                                        ),
                                    );
                                    const primaryDoc = docArr[0];
                                    if (!primaryDoc) {
                                        return null;
                                    }
                                    if (
                                        docArr.length > 1 &&
                                        show_history &&
                                        date_selector_frequency
                                    ) {
                                        return (
                                            <DocumentListItemWithDatePicker
                                                key={docArr[0]?.id}
                                                data={docArr}
                                                baseURL={baseURL}
                                                dateFrequency={
                                                    date_selector_frequency
                                                }
                                                style={{ zIndex: 500000 - j }}
                                            />
                                        );
                                    } else if (
                                        primaryDoc.level === "share_class" &&
                                        docArr.length > 1
                                    ) {
                                        return (
                                            <DocumentListItemWithShareClassSelector
                                                key={docArr[0]?.id}
                                                data={docArr}
                                                baseURL={baseURL}
                                                style={{ zIndex: 500000 - j }}
                                                availableLanguages={
                                                    languagesInDocList
                                                }
                                            />
                                        );
                                    } else if (docArr.length === 1) {
                                        return (
                                            <DocumentListItem
                                                key={primaryDoc.id}
                                                baseURL={baseURL}
                                                style={{ zIndex: 500000 - j }}
                                                {...primaryDoc}
                                            />
                                        );
                                    }
                                })}
                            </ul>
                        </Collapsible>
                    );
                })}
            </ul>
        </>
    );
};
