import { Loupe } from "../../../components/icons";
import { DisclaimerText } from "./Disclaimer";
import LatestQuarterlyReport from "./LatestQuarterlyReport";

const StickyBanner = ({ stuck, title }: { stuck: boolean; title?: string }) => {
    if (!stuck) {
        return null;
    }
    return (
        <div className="bg-secondary fixed left-0 right-0 top-[162px] z-[60] flex h-[56px] items-center justify-center sm:top-[176px] md:top-[192px] lg:top-[172px]">
            <div className="container flex items-center justify-between">
                <div className="flex items-center">
                    <Loupe />
                    <h2 className="!m-0 whitespace-nowrap pl-3 text-lg text-white">
                        {title}
                    </h2>
                </div>
                <DisclaimerText className="hidden max-w-[520px] text-center !text-xs font-light text-white sm:block" />
                <div className="min-w-[32px]">
                    <LatestQuarterlyReport
                        textClassName="!text-xs !w-max !mb-0"
                        iconContainerClassName="!h-[30px] !max-h-[30px] !w-[30px] !max-w-[30px] !mr-2"
                    />
                </div>
            </div>
        </div>
    );
};

export default StickyBanner;
