import { useDocumentContext } from "@hsl/fund-page/hooks/useDocumentContext";

import FundPageContent from "../../../components/FundPageContent";
import { DocumentListItem } from "./DocumentListItem";

export const KeyDocsSection = ({ keyDocsSetId }: { keyDocsSetId: number }) => {
    const { docsData, baseURL } = useDocumentContext();
    const keyDocumentsData = docsData.data?.find(
        (x) => x[0].set_id === keyDocsSetId,
    );
    if (!keyDocumentsData) {
        return null;
    }
    const { display_name } = keyDocumentsData[0];
    return (
        <div>
            <FundPageContent.Header>{display_name}</FundPageContent.Header>
            <ul className="mt-4">
                {keyDocumentsData[1].map((doc) => (
                    <DocumentListItem
                        key={doc.id}
                        baseURL={String(baseURL)}
                        withDownloadIcon
                        className="sm:w-full"
                        {...doc}
                    />
                ))}
            </ul>
        </div>
    );
};
